import axios from "@/utils/axios";
import { upimg } from "@/api";
import { audioPaly } from "@/utils";
import { tonguefail , yemian , tongueover , facetotongue  ,  lsd , lsd1 , fenxiing } from "@/utils/audio";

let step = {
    namespaced: true,
    state: {
      loading:false,
      show:false,
      no_show:false,
      explain:'',
      photoNun:1,
      dialog:false
    },
    mutations: {

      //重新检测
      againFun(state){
        state.no_show = false;
        state.loading = false;
        state.photoNun = 1;
        state.dialog = false;
      },

      //使用图片
      phoFun(state,params){
        state.dialog = false;
        let url = 'confirmAsk';
        let audio = tongueover;
        let flow = sessionStorage.getItem('flow');
        let flow_step = "";
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }
        if(params.type==3){
          state.explain = '舌诊成功，即将进入舌底拍摄';
          audio = lsd;
          url = 'confirmPsd';
        }else{
          state.explain = '舌诊成功，即将进入问诊';
        }
        setTimeout(function(){
          state.explain = '';
          //语音
          audioPaly.play(audio);
          params._this.$router.push({
            name:url
          });
        },2000)
      },

      //发送请求
      faceAxios (state, params) {
        let _this = this;
        state.loading = true;
        let flow = sessionStorage.getItem('flow');
        let flow_step = "";
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }

        axios (`/api${upimg}`, params.data,'POST').then ((data) => {
          if(data.code==1){
            state.loading = false;
            if(params.data.type==1){
                sessionStorage.setItem("faceID", data.data.id);
                if(flow_step==1||flow_step==6){ //面
                  state.explain = '面诊成功，即将进入问诊';
                  //语音
                  audioPaly.play(facetotongue);
                  setTimeout(function(){
                    state.explain = '';
                    let device = sessionStorage.getItem('device');
                    if(device && !(device=='android' || device=='windows') && (flow_step==5||flow_step==6)){
                      params._this.$router.push({
                        name:'prescribeQuestion'
                      });
                    }else{
                      params._this.$router.push({
                        name:'question'
                      });
                    }
                  },2000)
                }else{    //其他跳舌诊
                  state.explain = '面诊成功，即将进入舌诊';
                  setTimeout(function(){
                    state.explain = '';
                    params._this.$router.push({
                      name:'confirmTongue'
                    });
                  },2000)
                }
            }else if(params.data.type==2){
                sessionStorage.setItem("tongueID", data.data.id);
                state.explain = '舌诊成功，即将进入问诊';
                setTimeout(function(){
                  state.explain = '';
                  //语音
                  audioPaly.play(tongueover);
                  params._this.$router.push({
                    name:'confirmAsk'
                  });
                },2000)
            }else if(params.data.type==3){
                sessionStorage.setItem("tongueID", data.data.id);
                if(data.data.anduan=='正常'){
                  state.explain = '舌诊成功，即将进入舌底拍摄';
                  setTimeout(function(){
                    state.explain = '';
                    //语音
                    audioPaly.play(lsd);
                    params._this.$router.push({
                      name:'confirmPsd'
                    });
                  },2000);
                }else{
                  state.dialog=true;
                }
            }else if(params.data.type==4){
              sessionStorage.setItem("tongueBottomID", data.data.id);
              state.explain = '舌底拍摄成功';
              setTimeout(function(){
                state.explain = '';
              },2000);
              if(flow_step==13){
                //语音
                audioPaly.play(fenxiing);
                _this.dispatch('question/submitData',{_this:params._this});
              }else if(flow_step==14){
                //语音
                audioPaly.play(tongueover);
                params._this.$router.push({
                  name:'confirmAsk'
                });
              }
            }
          }else{
            state.loading = false;
            state.no_show = data.msg;
            state.photoNun = 0;
            console.log(params.data.type);
            if(params.data.type==1){
              //语音
              audioPaly.play(yemian);
            }else if(params.data.type==4){
              if(flow_step==13||flow_step==14){
                //语音
                audioPaly.play(lsd1);
              }
            }else{
              //语音
              audioPaly.play(tonguefail);
            }
          }
        }).catch((error)=>{
          state.loading = false;
          state.no_show = error.msg;
          state.photoNun = 0;
        });
      }
    },
    actions: {
      //异步方法
      axiosData ({ commit }, params) {
        commit ("faceAxios", params);
      }
    },
    getters: {},
  };
  
  export default step;